import { Injectable, inject } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class MetaService {
  private title = inject(Title);
  private meta = inject(Meta);
  private router = inject(Router);

  metaDescription = `BeAthletics, votre site d'athlétisme ! Calendrier des compétitons, résultats athlétiques, records des athlètes,... tout est sur BeAthletics !`;

  setBasicMetaTags() {
    this.setPageTitle();
    this.setDescription();
  }

  setPageTitle(page?: string) {
    const name = 'BeAthletics • ';
    if (page) {
      this.title.setTitle(name + page);
    } else {
      const route = this.router.url;
      const url = route.split('/')[1];
      let page = '';
      switch (url) {
        case 'admin':
          page = 'Admin';
          break;
        case 'athlete':
          page = 'Athlète';
          break;
        case 'calendar':
          page = 'Calendrier';
          break;
        case 'event':
          page = 'Compétition';
          break;
        case 'home':
          page = 'Home';
          break;
        case 'rankings':
          page = 'Rankings annuels';
          break;
        default:
          page = "Votre site d'athlétisme";
          break;
      }
      this.title.setTitle(name + page);
    }
  }

  setDescription() {
    this.meta.addTag({ name: 'description', content: this.metaDescription });
  }

  addTags(tags: MetaDefinition[]) {
    this.meta.addTags(tags);
  }
}
